import React from 'react'
import SectionTitle from './SectionTitle'
import {TextArea, TextInput} from './TextInput'
import styles from '../style'


const Contact = (props) => {
  return (
    <div className="container py-[30px]">
    <SectionTitle  title='CONTACT ME' subTitle="contact me to get acquainted, get in touch, or business cooperatione"/>

    <div className="flex flex-col md:flex-row gap-3 mt-5">
        <div className='flex-none w-full md:w-1/2 h-auto text-textHeadline'>
          <form action="/sendMessage" method="post">
        <TextInput type="text" name="name" children="Name"/>
        <TextInput type="email" name="email" children="Email" />
        <TextArea type="text" name="message" children="Message"/>

       <button type='submit' className='w-full p-2 rounded bg-primary text-textHeadline hover:bg-violet-800 transition ease-in'>Submit</button> 
    </form>
        </div>
        <div className='flex-none w-full md:w-1/2 h-auto'>
            <div className='w-full bg-bgSecondary rounded-2xl px-3 py-7'>
              <h1 className='text-textHeadline text-4xl font-bold'>i'm available for every opportunity!</h1>
              <p className='text-paragraph'>Interested in working with me? Need help on your project? Or just wanna say hi? I’d love to hear you about that.</p>
              <span className='text-textHeadline'><i className='fa fa-envelope'></i>{props.Datas.email}</span>
            </div>
        </div>
    </div>
    <div className='w-full mt-5 text-center'>
      {props?.Datas?.socmed?.map(data => (
          <a href="" className={`${styles['nav-link']}`}><i className={data.icon}></i></a>
      ))}

    </div>
    </div>
  )
}

export default Contact