import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import styles from '../style'

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import { Navigation, Pagination, Mousewheel, Autoplay , FreeMode} from "swiper";


const Modal = (props) => {
    const closeModal = ()=>{
        document.body.style.overflow = 'unset';
        props.setIsOpen(false);
    } 
  return (
    <div onClick={closeModal} className='fixed z-10 w-[100vw] h-[100vh] bg-gray-800/[0.5] top-0 left-0 '>
        <div onClick={e => e.stopPropagation()} className='absolute bg-bgPrimary rounded-3xl w-[90%] h-[90%] left-0 right-0 top-0 bottom-0 mt-3 md:my-auto mx-auto flex flex-col flex-none pb-3'>
            <h1 className='text-textHeadline p-5 text-3xl font-bold'>{props.Datas.name}</h1>
            <div className='absolute right-0 top-0 p-5'>
            <a onClick={closeModal} className='text-lg text-textHeadline hover:text-paragraph cursor-pointer p-2 bg-bgSecondary rounded-full'>X</a>
            </div>

            <div className='px-5'>
            <Swiper
        slidesPerView= {3}
        spaceBetween= {30}
        centeredSlides={true}
        freeMode={true}
        navigation={true}
        keyboard={true}
        autoplay={{
            delay: 5000,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,FreeMode, Pagination , Autoplay]}
        className="mySwiper"
      >
        {props.Datas.image.map(data => (
          <SwiperSlide><img src={data} alt={props.Datas.name} className='md:h-[150px] h-[75px]  w-auto'/></SwiperSlide>
        ))}

      </Swiper>
            </div>
            <div className='p-5 h-[85%] w-[99%] text-paragraph overflow-y-auto'>
                {props.Datas.description}
            </div>
            <div className='px-5 py-2 flex gap-3'>
              {props.Datas.link != "" && <a href={props.Datas.link} className='w-full p-2 rounded bg-primary text-textHeadline hover:bg-violet-800 transition ease-in'>Try It Out</a>}
              {props.Datas.github != "" && <a href={props.Datas.github} className='w-full p-2 rounded bg-none border border-primary text-textHeadline hover:bg-slate-800 transition ease-in'><i className='fa-brands fa-github'></i> Github</a>}
            </div>
        </div>
    </div>
  )
}

export default Modal