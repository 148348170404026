import React, { useState } from 'react'
import {SectionTitle} from './index'
import Modal from './Modal'
import './Portofolio.css'

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import "swiper/css/navigation";

import { Navigation, Pagination, Mousewheel, Autoplay , FreeMode} from "swiper";
import styles from '../style';

const Portofolio = (props) => {
    const [isModalOpen,setIsOpen] = useState(false);
  return (
    <div className="container py-[30px]">
    <SectionTitle  title='PORTOFOLIO' subTitle="in this section I will explain some of the projects I have worked on and explain the skills that I am capable of."/>
    <div className='pt-10 grid grid-cols-2 md:grid-cols-4 gap-3'>
    {props?.Datas?.portofolio?.map((data) => (
    <div className='portofolio-card'>
        <a onClick={() => {setIsOpen(true);        if (typeof window != 'undefined' && window.document) {
            document.body.style.overflow = 'hidden';
        }}} className='project-view' >
            <img src={data.thumbnail} alt="" className='h-full w-auto object-cover'/>
            <div className='background'>
                <button><i className='fa-solid fa-eye p-1 bg-bgPrimary text-textHeadline rounded-lg'></i></button>
            </div>
        </a>
        {isModalOpen && <Modal setIsOpen={setIsOpen} Datas={data}/>}
    </div>
    ))}
    </div>

    <div className='pt-10'>
        <p className='text-paragraph text-center pb-5'>I'm also able to perform the skills below</p>
    <Swiper
        slidesPerView= {3}
        spaceBetween= {30}
        breakpoints={{
            1060:{
                slidesPerView: 5,
                spaceBetween: 30,
            }
        }}
        freeMode={true}
        navigation={true}
        keyboard={true}
        autoplay={{
            delay: 5000,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Navigation,FreeMode, Pagination , Autoplay]}
        className="mySwiper"
      >
         {props?.Datas?.skills?.map((data) => (
            <SwiperSlide><i className={`${styles.portofolio['swiper-item']} ${data} text-paragraph`}></i></SwiperSlide>
         ))}
        

      </Swiper>
    </div>
    </div>
  )
}

export default Portofolio