import React from 'react'

const SectionTitle = (props) => {
  return (
    <div className="text-center">
        <h1 className='text-textHeadline text-4xl font-bold uppercase'>{props.title}</h1>
        <p className='text-paragraph'>{props.subTitle}</p>
    </div>
  )
}

export default SectionTitle