import React from 'react'
import SectionTitle from './SectionTitle'

const Posts = (props) => {
  return (
    <div className="container py-[30px]">
    <SectionTitle  title='RECENT POST' subTitle="Read some blogs/articles that I wrote."/>
    <div className='pt-10 grid grid-cols-1 md:grid-cols-3 gap-3 mb-5'>
        {props.Datas.posts == [] && <h1>No Recent Post Available</h1>}
        {props?.Datas?.posts?.map(data => (        <a href='' className='hover:scale-[1.01] transition ease-in delay-50 bg-bgPrimary flex flex-row md:flex-col p-3 rounded-2xl h-[150px] md:h-[400px] cursor-pointer'>
            <img src={data.postThumbnail} alt="" className='flex-none w-2/5 md:w-[100%] h-auto md:h-[200px] object-cover rounded-lg'/>
            <div className='pl-2 md:pt-2 h-auto w-auto text-ellipsis overflow-hidden'>
                <h1 className='text-lg text-textHeadline'>{data.postTitle}</h1>
                <p className='text-paragraph text-xs text-justify h-[100%] w-[100%] '>{data.postDescription}</p>

            </div>
        </a>))}


    </div>
    <div className='w-full'>
      <a className="w-full text-center bg-primary p-1 hover:bg-violet-800 transition ease-in rounded-full text-textHeadline inline-block" href={props.Datas.blogLink}>Read Other Post</a>
    </div>
    </div>
  )
}

export default Posts