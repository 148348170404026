import React from 'react'
import Avatar from '../assets/img/avatar-2.png'
import styles from '../style'

const Hero = (props) => {
  return (
    <div className="container">
        <div className=" flex flex-col-reverse md:flex-row items-center">
            <div className="my-10 md:my-0 flex flex-col justify-center md:h-[90vh] w-100 md:w-[50%] text-center md:text-start">
                <p className='text-textHeadline'>Hello 👋 ,I'm</p>
                <p className='text-textHeadline font-bold text-6xl'>{props.Datas.nama}</p>
                <p className='text-paragraph text-sm'>{props.Datas.bio}</p>
                {props.Datas.socmed?.map((data) => (
                    <div className='mt-3'>
                    <a className={`${styles['nav-link']}`} href={data.link}>
                    <i className={data.icon}></i>
                    </a>
                </div>
                ))}
                
            </div>
            <div className=''>
                <img src={Avatar} alt="" className='w-[300px] h-[300px] object-cover md:absolute  md:bottom-0 md:right-0 md:h-[500px] md:w-auto rounded-full md:rounded-none' />
            </div>
        </div>
    </div>
  )
}

export default Hero