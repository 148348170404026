import React from 'react'
import styles from '../style';
import {SectionTitle} from './index'

const About = (props) => {
  return (
    <div className="container py-[30px]">
            <SectionTitle  title='ABOUT ME' subTitle="Let's get to know me more closely"/>
            <div className='flex flex-col md:flex-row mt-5'>
                <div className='w-[100%] md:w-2/5 mb-3'>
                    <img src={props.Datas.aboutImg} alt="" className='h-[250px] md:h-[300px] w-[100%] object-cover object-center grayscale-[80%]'/>
                </div>
                <div className="md:w-3/5 text-justify text-paragraph md:pl-5">
                {props.Datas.aboutDesc}
                </div>
            </div>
            <div className='bg-bgPrimary mt-3 text-paragraph p-3 rounded-3xl grid grid-row-2 md:grid-cols-2'>
              <div className='px-3'>
                <h1 className={`${styles.about['headline-text']}`}>Education Background</h1>
                <ul className=''>
                  {console.log(props.Datas.experience)}
                {props?.Datas?.experience?.education?.map((data) => (
                  <li className='list-ex py-3'>
                    <h2 className={`${styles.about['subheadline-text']}`}>{data.name} <span className={`${styles.about['subheadline-span']}`}>({data.span})</span></h2>
                      <p className={`${styles.about['paragraph']}`}>
                          {data.description}
                          <ul className={`${styles.about['paragraph-list']}`}>
                      
                          {data.list?.map((data) => (
                              <li>
                                {data}
                              </li>
                        ))}
                        </ul>  
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
              <div className='px-3'>
              <h1 className={`${styles.about['headline-text']}`}>Work Experience</h1>
              <ul className=''>
              {props?.Datas?.experience?.work?.map((data) => (
                  <li className='list-ex py-3'>
                  <h2 className={`${styles.about['subheadline-text']}`}>{data.name} <span className={`${styles.about['subheadline-span']}`}>({data.span})</span></h2>
                  <p className={`${styles.about['paragraph']}`}>
                  {data.description}
                    <ul className={`${styles.about['paragraph-list']}`}>
                    {data.list?.map((data) => (
                    <li>
                      {data}
                    </li>
                    ))}
                    </ul>  
                  </p>
                </li>
                ))}
                </ul>
              </div>
            </div>
    </div>

  )
}

export default About;