const styles = {
  paddingX: "sm:px-16 px-6",
  "nav-link":
    "bg-primary text-textHeadline rounded-full text-[18px] py-[0.375rem] px-[0.75rem] inline-block mr-3 hover:bg-textHeadline hover:text-primary",
  about: {
    "headline-text":
      "uppercase text-textHeadline font-bold text-center text-xl",
    "subheadline-text": "text-textHeadline text-base",
    "subheadline-span": "text-xs",
    paragraph: "text-xs text-justify",
    "paragraph-list": "list-disc pl-5 pt-2",
  },
  portofolio: {
    "swiper-item": "w-[100%] text-[5rem] text-center",
  },
};

export default styles;
