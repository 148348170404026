import React, { Fragment, useState, useEffect } from "react";
import { Hero, Navbar, About, Portofolio, Posts, Contact } from "./components";

const App = () => {
  const [Datas, setDatas] = useState([]);

  const fetchData = async () => {
    await fetch("./backendData.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setDatas(data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Fragment>
      <section className="bg-bgPrimary h-[100vh] w-[100%]" id="home">
        <Navbar />
        <Hero Datas={Datas} />
      </section>

      <section className="bg-bgSecondary" id="about">
        <About Datas={Datas} />
      </section>
      <section className="bg-bgPrimary" id="projects">
        <Portofolio Datas={Datas} />
      </section>
      <section className="bg-bgSecondary" id="posts">
        <Posts Datas={Datas} />
      </section>
      <section className="bg-bgPrimary" id="contact">
        <Contact Datas={Datas} />
        <footer>
          <p className="text-paragraph text-center pb-5">
            Made with <span style={{ color: "#e25555" }}>&#9829;</span> by{" "}
            <a href="/" className="text-textHeadline">
              Faiz Rashid
            </a>
          </p>
        </footer>
      </section>
    </Fragment>
  );
};

export default App;
