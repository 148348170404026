import React from 'react'

const Navbar = () => {
  return (
    <nav className="container h-[10vh] py-5 flex justify-center items-center">
      <div>
        <div className="text-center font-bold text-xl text-textHeadline md:text-left ">
          <a href="">
          FaizRashid
          </a>
          </div>
          </div>

    <div className="ml-auto hidden md:inline-block">
          <div className="text-textHeadline ">
            <ul className='flex gap-5'>
              <li><a href="#home" className='hover:text-paragraph'>Home</a></li>
              <li><a href="#projects" className='hover:text-paragraph'>Projects</a></li>
              <li><a href="#posts" className='hover:text-paragraph'>Posts</a></li>
              <li><a href="#contact" className='hover:text-paragraph'>Contact</a></li>
            </ul>
          </div>
          </div>
    </nav>
  )
}

export default Navbar